.spinner {
    width: 140px;
    height: 140px;
    border: 3px solid rgb(128 128 128);
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    background: none;
    left: 0;
    right: 0;
    margin-left: auto;
  	margin-right: auto;
    animation: rotate-loading 1s linear infinite;
}

@keyframes rotate-loading {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
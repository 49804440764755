.mapContainer {
    position: relative;
    background: #F8F8F9;
    overflow: hidden;
    margin: auto;
    width: 100%;
    height: 500px;
}

.mapContainer svg {
    position: absolute;
    overflow: visible;
	transition: transform 0.2s;
    width: 700px;
    height: 600px;
	left:0;
}

.mapContainer > button:not(:hover) {
    background-color: #1976d217;
    color: #1976d2;
}

.mapContainer > button {
	transition: background-color .2s;
    position: absolute;
    z-index: 1;
    right: 70px;
    top: 30px;
    background-color: #1772E8;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 22px;
    border-radius: 50%;
    width: 31px;
    height: 31px;
}

.mapContainer > button + button {
    right: 30px;
}

.mapContainer path[fill][data-selected="false"][data-canhover="true"]:hover {
    filter: brightness(1.1);
    stroke-width: 5px;
}

.mapContainer path[fill][data-selected="true"][data-canhover="true"] {
    stroke: #1772E8;
    stroke-width: 3px;
}

.mapContainer path{
    cursor: pointer;
}

.mapContainer path:not([fill]) {
    fill:transparent;
	pointer-events: none;
}

.mapContainer > div {
    width: 500px;
    height: 500px;
    position: absolute;
    left: 0;
    top: 0;
    transition: transform .2s;
}

.mapContainer > button i {margin-top: -4px;display: block;}

.mapContainer > button:active {
    filter: brightness(.5);
}

div.mapContainer_scoreScale {
    width: 332px;
    margin: 24px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    text-align: left;    z-index: 1;
}

.mapContainer_scoreScale h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
}

.mapContainer_scoreScale > div {
    display: flex;
}

.mapContainer_scoreScale > div > div {
    flex-basis: 25%;
    text-align: center;
    padding:0 2px;
}

.mapContainer_scoreScale span {
    font-size: 12px;
    line-height: 16px;
}

.mapContainer path[data-canhover="false"], .mapInner {
    cursor: grab;
}

.mapContainer_scoreScale > div > div > i {margin-bottom: -2px;content:'';width: 100%;height: 8px;display: block;}

.mapContainer_scoreScale > div > div > i {background:inherit;border-bottom-right-radius:4px;border-top-right-radius:4px;}
.mapContainer_loading {
    width: 200px;
    height: 200px;
    margin: 150px 310px;
    display:flex;
    align-items:center;
    position:relative;
}
.mapContainer svg {
    opacity:0;
    animation:loading-opacity 1s linear both;
}
.mapContainer_loading:after {
    width:140px;
    height:140px;
    border:3px solid rgb(218, 218, 218);
    border-top:2px solid transparent;
    border-bottom:2px solid transparent;
    border-radius:50%;
    content:'';
    display:block;
    position:absolute;
    left:0;
    right:0;
    margin:auto;
    animation:rotate-loading 1s linear infinite;
}






@keyframes rotate-loading {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

@keyframes loading-opacity {
    0%  {opacity: 0}
    100%{opacity: 1}
}


/* dark map ------------------------------------- 

svg > *:not([data-canhover="true"]) {fill: transparent;}
svg > * {stroke: #4b9596;}
.mapContainer {background: linear-gradient(180deg, #101123, #091e2b);}
path[fill][data-selected="true"][data-canhover="true"] {stroke: #ffffff;}
.mapContainer_scoreScale * {color: white;}
.mapContainer > button:not(:hover) {background: #ffffff0f;}

 ---------------------------------------------- */


.userTable_popup_dateRange {
    width: 646px;
    background: white;
    z-index: 11;
    position: absolute;
    top: -38px;
    left: -24px;
	opacity:0;
	transition:all .2s;
	pointer-events:none;
    display: flex;
    flex-wrap: wrap;
}
.userTable_tool_field[data-open="dateRange"] + * + * {
    opacity:1;
	pointer-events:initial;
    box-shadow: 0 10px 15px -9px black;
}
.userTable_tool_field[data-open="dateRange"] + * {
    opacity:.1;
	pointer-events:initial;
}

.dateRange_section {
    display: flex;
    flex-wrap: wrap;
    width: 290px;
    padding: 10px;
    margin: auto;overflow: hidden;
    padding: 0;
}

.dateRange_day:not(#_):not(#_)  {
    flex-basis: calc( 100% / 7 );
    font-size: 10px;
    line-height: 20px;  
    background: transparent;
    color: black;
    padding: 10px 0;
    min-width: initial;
	position:relative;
	filter:initial;
}
button.dateRange_day[data-selected="true"]:not(#_):not(#_) {
	color:white;
}
button.dateRange_day:not(#_):before {
    content: "";
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    border-radius: 50%;
    margin: -4px auto;
    left: 0;
    right: 0;
    z-index: -1;
}
button.dateRange_day[data-between="true"]:not(#_):before {
    background: #d9ecf6;
    border-radius: 0px;
    left: -30px;
    width: 101px;
    z-index: -2;
}
button.dateRange_day[data-selected="true"]:not(#_):before {
    background: #409ed1;
}
button.dateRange_day[data-grayselected="true"]:not(#_):before {
    background: #b4b4b4;
}
button.dateRange_day[data-grayselected="true"]:not(#_) {
    color: #fff !important;
}
button.dateRange_day:hover:not([data-selected="true"]):before {
    background: #9bc4db !important;
    left: 0px !important;
    width: 30px !important;
    z-index: -1 !important;
    border-radius: 50% !important;
}
.dateRange_topRow {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    flex-basis: 100%;
}

.dateRange_topRow:before {content: "";display: block;flex-basis: 368px;}

.dateRange_topRow button {
    padding: 6px 16px !important;
    margin: 8px!important;
    min-width: 110px !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.dateRange_calendarArea {
    flex-basis: 50%;
    text-align: center;
}

.dateRange_calendarArea_topRow {
    text-align: center;
}

.dateRange_calendarArea_topRow button {
    min-width: 24px !important;
    width: 24px !important;
    padding: 0;
    height: 24px;
    vertical-align: middle;
    margin: 0 16px;
    text-align: center;
    transform: rotate(90deg);
    background: transparent !important;padding: 0 !important;
}

.dateRange_calendarArea_topRow > i + button {
    transform: rotate(-90deg);
}


.dateRange_calendarArea_topRow button i {
    width: 14px;
    height: 14px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
}

.dateRange_calendarArea_topRow > i {
    font-style: initial;
    font-size: 14px;
    line-height: 20px;
    width: 140px !important;
    display: inline-block;
}

.dateRange_section.dateRange_head {
    margin: auto;
    margin: 8px auto 0px;
}


.dateRange_section.dateRange_head * {
    font-weight: 600;
}

.dateRange_section:not(.dateRange_head) {
    margin-bottom: 24px;
}
button.dateRange_day[data-disablea="true"],button.dateRange_day[data-disableb="true"] {
    color: rgb(168, 168, 168) !important;
    pointer-events: none;
}

.dateRange_section:not(.dateRange_head) .dateRange_day:not([data-date]):before {
    content: '';z-index: 1;background: white;width: 36px;height: 40px;display: block;position: absolute;left: 0;top: 0;box-shadow: 10px 0 10px white;}
.dateRange_section:not(.dateRange_head) .dateRange_day[data-between="true"]:last-child:after {
    content: '';
    z-index: 1;
    background: white;
    width: 36px;
    height: 40px;
    display: block;
    position: absolute;
    left: 50px;
    top: 0;
    box-shadow: -10px 0 10px white;
}
button.dateRange_clear:not(#_) {
    position: absolute;
    min-width: initial;
    padding: 0;
    width: 20px;
    height: 20px;
    left: 302px;
    top: 2px;
    z-index: 12;
    background-color: transparent;
    opacity:.5;
    transition:all .2s;
}

button.dateRange_clear:not(#_) i {
    width: 12px;
    height: 12px;
    background-size: contain;
    display: block;
    background-position: center;
    margin: auto;
}

input.userTable_tool_field:not([data-open="dateRange"]) + * + * + *:not(#_) {
    opacity: 0;
    pointer-events:none;
}
button.dateRange_clear:hover:not(#_) {
    opacity:1;
}
.userTable_popup_bg {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    width: 120vw;
    height: 120vh;
    z-index: 10;
    cursor: pointer;
	opacity:0;
	transition:all .2s;
	pointer-events:none;
}

.userTable_tool_field[data-open="dateRange"] {
    z-index: 12;
    position: relative;
}

i.userTable_tool_label[data-open="dateRange"] {
    z-index: 13;
}

.userTable_tool_inner {
    display: inline;
    position: relative;
}


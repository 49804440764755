.userTable_heading {
	text-align: left;
}

.userTable_heading h4 {
	font-size: 14px;
	margin: 0;
}

.userTable_head {
	background: #E2E4E9;
	padding: 18px 16px;
}

.userTable_tool {
	text-align: left;
}

.userTable_tool_field {
	margin: 0 16px 16px 0;
	font-size: 14px;
	padding: 14px 12px;
	width: 336px;
	border: 1px solid #9BA4B5;
}

.userTable_tool ::-webkit-input-placeholder {
	color: #9BA4B5;
}

.userTable_tool ::-moz-placeholder {
	color: #9BA4B5;
}

.userTable_tool ::-ms-placeholder {
	color: #9BA4B5;
}

.userTable_tool ::placeholder {
	color: #9BA4B5;
}

.userTable_tool_label {
	position: absolute;
	margin: -9px 0 0 16px;
	font-size: 12px;
	color: #9BA4B5;
	font-style: normal;
	background: white;
	padding: 0 4px;
}

.userTable_pagination {
	margin-top: 8px;
}

ul.userTable_page_container li {
	list-style: none;
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #F8F8F9;
	margin: 8px;
	border: 1px solid #E2E4E9;
}

ul.userTable_page_container a {
	font-size: 14px;
	color: #151515;
	margin-top: 9px;
	display: block;
}

ul.userTable_page_container li.userTable_page_active {
	background: #111121;
	color: white;
}

.userTable_tool button {
	background: #409ED1;
	color: white;
	font-size: 16px;
	padding: 8.5px 20px;
	border: none;
	line-height: 23px;
	min-width: 170px;
	margin-top: 4px;
	margin-top: 4px;
}

.userTable_tool+.userTable_tool {
	text-align: right;
}

.userTable_tool button:hover {
	filter: brightness(1.1);
}

.userTable_tool button:active {
	filter: brightness(.8);
}

.userItem:empty {
	height: 67px;
}

[data-switching="true"] .userItem {
	opacity: 0;
}

.userTable_list:empty+.userTable_page {
	opacity: 0;
}

.userItem[data-session=""] {
	opacity: 0;
}

.userItem:not([data-session=""]) {
	opacity: 1;
	transition: all .5s;
}

.userTable[data-nousers="true"] .userTable_list:after {
	content: 'No Results';
}

.userTable_list:empty:after {
	opacity: 1;
}

.userTable_list {
	min-height: 800px;
	position: relative;
}


.userTable_tool button[data-count="0"] {
	opacity: 0;
}

.userTable_tool button:not([data-count="0"]) {
	opacity: 1;
	transition: opacity 1s;
}
html{
    overflow-y: scroll;
}

.noAccessDashInner > .MuiGrid-item > div {
    width: 100%;
}
.noAccessDashInner > .MuiGrid-item.MuiGrid-grid-xs-4{
    max-height: 600px;
    overflow: auto;
}
.noAccessDashInner > .MuiGrid-item {
    padding: 20px;
}

.noAccessDashInner {
    margin-top: 32px;
	text-align:left;
}

h3.noAccessDashboard_title {
    text-align: left;
    padding: 0 16px;
    color: #4F4F4F;
    font-size: 24px;
	margin:0;
}

.noAccessDashboard {
    max-width: 1400px;
    margin: auto;
}
.noAccessDash_InnerBox {
    background: #F8F8F9;
    width: 100%;
	padding:24px;
}
.noAccessDashInner button {
    color: white;
    opacity: 1 !important;
    text-align: center;
    background: #089ed1;
    border: none;
    padding: 7px 20px;
    font-size: 18px;
    cursor: pointer;
}
.noAccessDash_InnerBox_white {
	padding:24px 0;
}
.noAccessDash_InnerBox_white h3{
	padding-left:0px;
}
.noAccessDash_InnerBox + .noAccessDash_InnerBox {
    margin-top:32px;
}
.noAccessDash_InnerBox * {
    font-size:16px;
	line-height:24px;
	color:#151515;
	margin-bottom:0px;
}

select.noAccessDashboard_select {
    position: absolute;
    margin: 5px;top:18px;
}
.noAccessDashInner input[type="checkbox"] {
    zoom: 1.5;
    float: left;
    margin: 10px;
}
.noAccessDashInner input[type="radio"] {
    zoom: 2;
    margin-right: 8px;
	vertical-align: top;
}
.noAccessDashInner label[for] {
    display:block;
	margin-bottom:16px;
}
.noAccessDash_InnerBox_indent{
    margin-left:40px;
}
.noAccessDash_InnerBox > img {
    width: 100%;
    height: auto;
}
.noAccessDash_InnerBox h4 img{
	margin-right: 13px;
}
.noAccessDash_InnerBox h4 span {
    display: inline-block;
    white-space: pre-wrap;
    vertical-align: middle;
    padding-right: 54px;
}

.noAccessDash_InnerBox h4.noAccessDash_nowrap {
    white-space: nowrap;
}
.noAccessDash_topBar {
    background: #F8F8F9;
    padding: 23px;
}
.noAccessDash_topBar > * {
    max-width:1360px;
	width:100%;
	margin:auto;
}

button.noAccessDash_exitLeft,button.noAccessDash_exitRight {
    background: none;
    border: none;
	text-decoration: underline;
	font-size:16px;
	line-height:24px;
}

.noAccessDash_topBar > * > div:nth-child(1) {
    text-align: left;
}

.noAccessDash_topBar > * > div:nth-child(2) {
    text-align: right;
}

button.noAccessDash_exitLeft i {
    width: 12px;
    height: 12px;
    background-size: contain;
    display: inline-block;
    margin-right: 8px;
}

button.noAccessDash_exitRight i {
    width: 12px;
    height: 12px;
    background-size: contain;
    display: inline-block;
    margin-left: 8px;
}
.noAccessDash_wide {
    max-width: 933px;
    margin: 40px auto 0 !important;
}
.noAccessDash_InnerBox hr {
    margin: 16px 0;
}

.noAccessDash_wide + div {
    max-width: 940px;
    margin: 24px auto;
}
.noAccessDash_InnerBox_white + hr {
    margin-top: -8px;
	margin-bottom: 8px;
}
.noAccessDash_InnerBox_white textarea{
	width:100%;
	min-height:100px;
	border:1px solid #151515;
	margin-bottom:16px;
}

.noAccessDashboard button::after {
    content: "";
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDkvMjMvMjGmAQwpAAAA8ElEQVRIie2VsXECMRBF/3KJMvYuIhRXCSVcB5gO6MB04BYowR1wISFkDqEC5EwR38kdA5zBOmZxxEarndn3diSNlKFHqJZz54ajGMNXn76kKIpykedj5vmYqr5K7Rukwkm+Pz7eH/B28mb66Qv+gv8vXFr41T3fkPw04G9FtZyL8MMA1gmS9QA41gDCMwRAs0WqfiIiq7N6TXJhwN+eMlX/dnnIpYUAWZvEGDbO6V5EKgAQwcS5QmI81CaCZ0my64K1pCOwlvwqsJTcFFhJ7gpuS/Q7xrBOEST9ySHsliRnzXIHwOKd6oaqr1S979PzA78RmC5fZLYtAAAAAElFTkSuQmCC);
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: text-top;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(1);
    transform: rotate(180deg);
    margin: 0 -8px 0 8px;
}

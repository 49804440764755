.RegionPicker-item {
	width: 25%;
}
.RegionPicker-buttonContent {
	text-transform: none;
}
.RegionPicker-buttonContent > img {
	width:200px;
	height:200px;
}
.RegionPicker-item:hover {
    filter: brightness(1.5);
}
div.RegionPicker > * {
    display: block;
}
div.RegionPicker > * > * {
    display: inline;
}
.regions {
	display: flex;
	width: 80%;
	margin: auto;
}
#unity-canvas[data-start="true"] {
    z-index: 1;
    position: relative;   
	width: 100%;
    height: 100%;
}

.noExperience {
    width: 100%;
    height: fit-content;
}

.userDash_experience{
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
	line-height: 0 !important;
}

.userDash_experience .fullScreen{
    position: absolute;
    z-index: 2;
    background: #00000087;
    color: white;
    border: none;
    right: 0;
    top: 0;
    text-align: right;
    opacity: 0;
    transition: opacity .2s;
    padding: 7px 12px 10px;
}

.userDash_experience:hover .fullScreen{
	opacity:.5;
}

.userDash_experience .fullScreen:hover{
	opacity:1;
}

.userDash_experience .startExperience:after,
.userDash_experience .fullScreen:after{
	display:none;
}

.userDash_experience .fullScreen path {
    fill: white;
}

.userDash_experience .startExperience {
    color: white;
    opacity: 1 !important;
    text-align: center;
    background: #089ed1;
    border: none;
    padding: 7px 20px;
    font-size: 18px;
    cursor: pointer;
}

.userDash_InnerBox.userDash_experience {
    padding: 0;
	position:relative;
    width:890px !important;
    height:516px !important;
}

.userDash_experience .startExperience {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 140px;
    text-align: center;
    margin-top: 24%;
}

canvas[data-start="true"] + .startExperience {
    background:none;
	color:transparent;
	pointer-events:none;
}

canvas[data-start="true"] + .startExperience:after {
    width: 140px;
    height: 140px;
    border: 3px solid rgb(255 255 255);
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    background: none;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: -90px;
    animation: rotate-loading 1s linear infinite;
}

@keyframes rotate-loading {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

.navBar_inner {
    max-width: 1442px;
    display: flex;
    margin: auto;
    height: 100%;
    align-items: center;
    width: 100%;
}

.navBar {
    background: white;
    height: 69px;
	border-bottom:1px solid #D3D7DE;
}

.navBar_inner > dropdown {
    display: inline-block;
}

.navBar_inner .navBar_icon {
    flex-basis: 100%;
    text-align: left;
    height: 100%;
    line-height: 0px;
    padding: 15px;
}
.navBar_inner > .navBar_username {
    flex-basis: 400px;
    text-align: right;
}
.navBar_inner > .navBar_username * {
    margin:0;
}
.navBar_inner > .dropdown {
    flex-basis: 70px;
    text-align: right;
}

.navBar_icon img {
    height: 40px;
    width: auto;
}

.navBar_inner > .dropdown > button {
    background-color: transparent !important;
    margin: 15px;
    border: none;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
    width: 40px;
}
.navBar_inner > .dropdown > button > img {
    width: 40px;
    left: 0;
    position: absolute;
    right: 0;
    margin: auto;
    top: 15px;
}
.navBar_inner > .dropdown > button:after {
    display: none;
}
.navBar_inner > .dropdown > button{
    box-shadow: 0 0 0 0.2rem #fff !important;
}
.navBar_inner > .dropdown.show > button {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 50%) !important;
}

.navBar .dropdown-menu.show > * {
    display: block;
    background: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    transition: all .2s;
	text-decoration:none;
	color:black;
}

.navBar .dropdown-menu.show > *:hover {
    background: #e8e8e8;
}

.navBar .dropdown-menu.show {
    margin-top: 10px !important;
    box-shadow: 0 10px 10px -10px;
    border-top: 6px solid #089ed1;
    border-radius: 6px;
}

.changeLang {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.changeLang:hover {
    text-decoration: underline;
}
.scoreTotals_heading h3 {
    color: #4F4F4F;
    font-size: 24px;
	margin:0;
}

.scoreTotals {
    background: #F8F8F9;
    width: 100%;
	height:200px;
}

.scoreTotals_heading {
    text-align: left;
    padding: 24px;
}

.scoreTotals_staticCircle {
    position: absolute;
}
.scoreTotals_staticCircle *{
    color: #E2E4E9;
	stroke-linecap: round;
}

.scoreTotals_valueCircle {
    position: absolute;
}
.scoreTotals_valueCircle *{
    color: #1772E8;
	stroke-linecap: round;
}

.scoreTotals_total{
    text-align:left;position: relative;
    padding:16px 24px;
}
.scoreTotals_icon{
    height: 22px;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}
.scoreTotals_icon_box{
    height: 64px;
    width: 64px;
	text-align:center;
}

.scoreTotals_text {
    position: absolute;
    top: 12px;
    left: 84px;display: inline-block;line-height: 0;}
[data-mode="0"] .scoreTotals_text {
    top: 4px;
}
.scoreTotals_icon_box {
    display: flex;
    vertical-align: super;
    align-items: center;
    text-align: center;}

.scoreTotals_text h4 {
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0 0 20px;
}

.scoreTotals_text span {
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
}

[data-mode="0"] .scoreTotals_total {
    max-width: 100%;
}

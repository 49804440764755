.userItem_field {
    text-align: left;
}

.userItem_field p {
    margin: 0px;
	color:#151515;
	font-size:14px;
}

.userItem {
    margin-top: 8px;
    padding: 0 16px;
    background: #F8F8F9;
    overflow: hidden;
}

.resultBar {
    width: 140px;
    position: relative;
}

.resultBar span p {
    font-weight: bold;
    margin: 0;
}

.resultBar .MuiLinearProgress-colorPrimary {
    background-color: #E2E4E9;
    height: 8px;
    border-radius: 50px;
}

.iconContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.arrow {
    height: 6px;
}

.button {
    background-color: transparent;
    border: 0;
}
.iconContainer > button {
    width: 20px;
    transform-origin: 11px 14px;
}
.userItem[data-index="0"] {
    animation: fadein 400ms ease-out both !important;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.userItem[data-open="true"] {
    animation: openDrawer 250ms ease-out both;
	transition: all .2s;
}

@keyframes openDrawer {
    0% {
        height: 0;
    }
    100% {
        height: 65px;
    }
}

.userItem:not([data-index="0"]):not([data-open="false"]):not([data-open="true"]) {
	height: 0;
	margin: 0;
}
.userItem[data-open="false"]:not([data-index="0"]) {
    animation: closeDrawer 250ms ease-out both;
	transition: all .2s;
}

@keyframes closeDrawer {
    0% {
        height: 65px;
    }

    100% {
        height: 0;
        margin: 0;
    }
}

.userItem[data-open="true"]:not([data-index="0"]) p,
.userItem[data-open="true"]:not([data-index="0"]) span {
    animation: fadeIn 400ms ease-out both;
}

@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.userItem[data-open="false"]:not([data-index="0"]) p,
.userItem[data-open="false"]:not([data-index="0"]) span {
    animation: fadeOut 200ms ease-out both;
}

@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

.userItem[data-index="0"] {
    position: relative;
    height: 65px !important;
}


.userItem[data-open="true"] button + button {
    transform: rotate(180deg);
}

.userItem button {
    transition: all .2s;
}

.dividerContainer {
    height: 8px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    width: 80%;
    margin-left: 10%;
    z-index: 1;
}

.dividers{
    background-color: #F8F8F9;
    height: 8px !important;
    width: 4px !important;
}

.avatar {
    border-radius: 50%;
    border: 3px solid #E2E4E9;
    padding: 2px;
    width: 48px;
    margin-right: 12px;
}
.userItem:not([data-index="0"]) > div > div:nth-child(1) * {
    display: none;
}

.userItem_badge {
    background-color: #4083D6;
    border-radius: 0;
    color: #ffffff;
    font-weight: bold;
    font-size: 10px;
    line-height: 11px;
    padding: 2px 4px;
	margin-top:4px;
    display: table;
}
.sessionContent {
    font-size: 14px;
    line-height: 20px;
}
.userItem_inner {
    align-items: center;
	height:100%;
}
.userItem[data-open="true"]:not([data-index="0"]) {
    padding-top: 8px;
    margin-top: 0px;
}
.userItem[data-open="false"]:not([data-index="0"]) {
    padding-top: 0px;
    margin-top: 0px;
}
.userItem_field i {
    font-style: initial;
    font-weight: bold;
}
.userItem span.MuiLinearProgress-root:after {
    width: 100%;
    height: 100%;
    background: #e2e4e9;
    content: '';
    display: block;
}

.userItem span.MuiLinearProgress-bar {
    background: inherit;
}
.resultBar span p {
    line-height: 8px;
    margin-bottom: 8px;
}

[data-session="void"] .userItem_field:nth-child(2) p {
    opacity: 0;
}

.userItem .userTable_popup_bg{
    opacity: 0 !important;
    pointer-events: initial !important;
}
.userItem_popup_details{
    opacity: 1;
    pointer-events: initial;
	transition:opacity .2s;
}
.userItem_popup_details span{
    margin: 8px 0;
    display: block;
}

.userItem_popup_details{
    position:absolute;
    background:#fff;
    z-index: 101;
    top: -8px;
    right: -12px;
    padding:8px 16px;
    border: 1px solid #a1a7b3;
}

.userItem .iconContainer:not([data-open="details"]) .userTable_popup_bg, 
.userItem .iconContainer:not([data-open="details"]) .userItem_popup_details{
    opacity: 0 !important;
    pointer-events: none !important;
}

.userItem[data-index="0"][data-popupopen="details"] {
    z-index: 100;
    overflow:visible;
}
.userItem[data-index="0"] {
    overflow:visible;
}

.userItem_popup_container{
    position:relative;
}
button.userTable_popup_close {
    width: 20px;
    height: 20px;
    right: 16px;
    position: absolute;
    top: 10px;
    border:none;
    background:none;
}

button.userTable_popup_close i {
    width: 20px;
    height: 20px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    opacity: .5;
}

button.userTable_popup_close i:hover {
    opacity: 1;
}
html {
    overflow-y: scroll;
}

.adminDashInner>.MuiGrid-item>div {
    width: 100%;
}

.adminDashInner>.MuiGrid-item {
    padding: 20px;
}

.adminDashInner {
    margin-top: 32px;
}

h3.adminDashboard_title {
    text-align: left;
    padding: 0 16px;
    color: #4F4F4F;
    font-size: 24px;
    margin: 0;
}

.adminDashboard {
    max-width: 1400px;
    margin: auto;
}

[data-mode="0"] .scoreTotals_heading {
    padding-bottom: 18px;
}

[data-mode="0"] .scoreTotals_totals {
    display: block;
}

[data-mode="0"] .scoreTotals_total {
    padding: 6px 24px;
}

[data-mode="0"] .scoreTotals {
    height: auto;
}

.adminDashboard[data-switching="true"] .scoreTotals>*,
.adminDashboard[data-switching="true"] .questionTotals>* {
    opacity: .3;
    transition: opacity .1s;
}

.scoreTotals>*,
.questionTotals>* {
    transition: opacity .5s;
}

.adminDashboard[data-switching="true"] .userItem>* {
    opacity: 0;
}

select.adminDashboard_select {
    position: absolute;
    margin: 5px;
    top: 18px;
}

.resetButton {
    background-color: #409ED1;
    border: none;
    padding: 8.5px 20px;
    color: white;
    text-transform: uppercase;
}

.warningIcon {
    height: 20px;
    padding-right: 5px;
}